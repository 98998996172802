var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"container--fluid text-center"},[_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',{},[_c('span',{staticClass:"blue--text text-h6"},[_vm._v(" Stringbox Ekle ")])]),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"col-12 d-flex"},[_c('v-btn',{staticClass:"mt-2",attrs:{"outlined":"","color":"indigo","dense":""},on:{"click":_vm.createDevice}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Stringbox Ekle ")],1),_c('div',{staticClass:"col-2 pt-1"},[_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.santralList,"hide-details":"","label":"santral list"},model:{value:(_vm.santralModel),callback:function ($$v) {_vm.santralModel=$$v},expression:"santralModel"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.devheaders,"items":_vm.devdesserts,"items-per-page":_vm.itemPerPage,"dense":""},on:{"update:itemsPerPage":function($event){_vm.itemPerPage=$event},"update:items-per-page":function($event){_vm.itemPerPage=$event}},scopedSlots:_vm._u([{key:"item.stringboxStatus",fn:function(ref){
var item = ref.item;
return [_c('Chips',{attrs:{"payload":{
                color: item.stringboxStatus === 'Active' ? 'green' : 'red',
                text: item.stringboxStatus === 'Active' ? 'Aktif' : 'Pasif',
                textColor: 'white',
                small: true
              }}})]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItemOpen(item)}}},[_vm._v(" mdi-pencil-circle ")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete-circle ")])]}}])})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"col-12 mt-5 pt-0 pb-0"},[_c('v-card-title',[_c('span',{staticClass:"blue--text"},[_c('h2',[_vm._v("Cihaz Ekle")])])]),_c('v-card-text',[_c('Stringbox',{attrs:{"stringdata":_vm.stringdata,"santral-list":_vm.santralList,"santral-data":_vm.santralData},on:{"stringlist":_vm.getStringbox_List}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","dense":"","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Kapat ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }