<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Datalogger
      </v-stepper-step>

      <v-divider />

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Cihaz tipi
      </v-stepper-step>

      <v-divider />

      <v-stepper-step step="3">
        Veri
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row
          class="pt-4"
          align="center"
        >
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="santralModel"
              outlined
              dense
              :items="santralList"
              :menu-props="{maxHeight: '300'}"
              label="santral"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="dataloggerModel"
              outlined
              :items="dataloggerList"
              :menu-props="{maxHeight: '300'}"
              label="Datalogger"
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="stringboxTypeModel"
              :items="inverterTypeList"
              :menu-props="{maxHeight: '300'}"
              label="Cihaz Listesi"
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>

        <v-row class="ml-5 mb-4 mt-4">
          <v-btn
            color="primary"
            dense
            outlined
            @click="e1 = 2"
          >
            Sonraki
          </v-btn>
        </v-row>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-row
          class="pt-4"
          align="center"
        >
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.stringboxDeviceSerial"
              label="Serial"
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.STRINGBOXNAME"
              label="STRINGBOXNAME"
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.ID"
              hide-details
              label="ID"
              outlined
              dense
            />
          </v-col>

          <!-- <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="formdata.STRINGBOX"
              label="STRINGBOX"
              outlined
              dense
              disabled
              hide-details
            />
          </v-col> -->

          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.stringQuantity"
              label="String Sayısı"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.voltageQuantity"
              label="Voltaj Sayısı"
              outlined
              dense
              hide-details
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="formdata.stringPanelQuantity"
              label="String Panel Sayısı"
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>

        <v-row class="mb-4 mt-4">
          <div class="col-12 d-flex">
            <v-btn
              class="ml-2 mb-4 ml-2 mt-3"
              @click="addMttpFunc"
            >
              <v-icon>mdi-add</v-icon>
              MPPT Ekle
            </v-btn>
            <v-spacer />
            <div class="ml-4 mr-5 ">
              <v-switch
                v-model="stringboxStatus"
                label="Durum"
                hide-details
                inset
              />
            </div>
          </div>

          <div
            v-for="(mttp, k) in MPPTList"
            :key="k"
            class="col-12 d-flex mt-0 pt-0 pt-0 pb-0"
          >
            <v-text-field
              v-model="MPPTList[k].text"
              label="MPPT"
              class="col-6 mr-2"
              outlined
              dense
              hiden-details
              hiden-footer
              hiden-hint
            />

            <v-select
              v-model="MPPTList[k].dcvoltage"
              :items="deviceParameterList"
              :menu-props="{maxHeight: '300'}"
              label="Voltage Listesi"
              class="col-6 ml-2"
              outlined
              multiple
              hiden-details
              hiden-footer
              hiden-hint
              dense
            />
          </div>
        </v-row>
        <v-row class="ml-5 mb-4 mt-4">
          <v-btn
            color="primary"
            dense
            outlined
            @click="e1 = 3"
          >
            Sonraki
          </v-btn>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-row class="mb-0 mt-0 pt-0 pb-0">
          <v-col cols="12">
            <v-data-table
              v-model="deviceParameterModel"
              :headers="devheaders"
              :items="deviceParameterList"
              class="elevation-1"
              dense
              item-key="value"
              show-select
              :items-per-page="itemPerPage"
            />
          </v-col>
          <v-col
            cols="12"
            class="d-flex"
          >
            <v-btn
              color="primary"
              dense
              outlined
              @click="e1 = 1"
            >
              Sonraki
            </v-btn>
            <hr>
            <hr>
            <hr>
            <v-btn
              dense
              outlined
              @click="setStrinboxCreate"
            >
              Kaydet
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { getDataloggerList } from '@/api/Request/datalogger';
import { getInverterTypeList } from '@/api/Request/inverter';
import { setStringbox } from '@/api/Request/stringbox';
import { getDeviceList } from '@/api/Request/farm';
import helper from '@/api/helper';

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['stringdata', 'santralData', 'santralList'],
  data: () => ({
    e1: 1,
    deviceTypeModel: 'STRINGBOX',
    stringboxTypeModel: '',
    inverterTypeList: [],
    inverterTypeData: [],
    itemPerPage: 10,
    deviceParameterList: [],
    deviceParamHeader: [],
    deviceParameterModel: [],
    dataloggerList: [],
    devheaders: [],
    dataloggerModel: '',
    deviceList: [],
    deviceModel: '',
    dataloggerData: [],
    santralModel: '',
    stringboxTypeDeviceId: '',
    MPPTList: [],
    mttpAddModel: [],
    formdata: {},
    mttpIndex: 0,
    stringboxStatus: true,
  }),
  watch: {
    deviceTypeModel() {
      // this.getInverterTypeList();
    },
    deviceParameterModel() {},
    dataloggerModel() {
      this.getInverterTypeList();
    },
    stringboxTypeModel() {
      this.getInverterType();
    },

    santralModel() {
      if (this.santralList.length > 0) {
        this.formdata.santralCode = this.santralData[this.santralModel].santralCode;
        this.formdata.santral = this.santralData[this.santralModel].santral;
      }

      this.getDatalogger();
    },
    stringdata() {
      this.santralModel = '';
      this.dataloggerModel = '';
      this.stringboxTypeModel = '';
      this.santralData = {};

      this.newStringData();
      this.deviceParameterList = [];
      this.deviceParameterModel = [];
      this.e1 = 1;
      this.deviceTypeModel = 'STRINGBOX';
      this.deviceModel = '';
      this.setInverterDataFunc();
    },
  },
  mounted() {
    const storage = window.localStorage;
    // eslint-disable-next-line camelcase
    const user_company = JSON.parse(window.atob(storage.getItem('user_company')));
    this.companyCode = user_company.companyCode;
    this.prefix = user_company.prefix;
    this.newStringData();

    this.setInverterDataFunc();
  },
  methods: {
    addMttpFunc() {
      this.mttpIndex += 1;

      const keys = `MPPT${this.mttpIndex}`;
      // this.formdata.MPPTList[keys] = []
      this.MPPTList.push({
        text: keys,
        dcvoltage: [],
      });
    },
    newStringData() {
      this.formdata = {
        stringPanelQuantity: '',
        voltageQuantity: '',
        stringQuantity: '',
        stringboxStatus: '',
        STRINGBOX: '',
        STRINGBOXNAME: '',
        stringboxDeviceSerial: '',
        stringboxTypeModel: '',
        dataloggerModel: '',
        santralCode: '',
        santral: '',
        ID: '',
        deviceParameterModel: '',
        MPPT: [],
      };
    },
    setInverterDataFunc() {
      if (this.stringdata.general_measure_list !== undefined) {
        this.devheaders = [
          { text: 'text', value: 'value' },
          { text: 'key', value: 'key' },
          { text: 'unit', value: 'unit' },
          { text: 'type', value: 'type' },
          { text: 'formuletype', value: 'formuletype' },
        ];
        this.deviceParameterList = this.stringdata.general_measure_list;
        this.deviceParameterModel = this.stringdata.general_measure_list;
        this.stringboxTypeModel = this.stringdata.stringboxTypeDeviceId;

        this.formdata = { ...{}, ...this.stringdata };
        this.stringboxStatus = this.stringdata.stringboxStatus === 'Active';
        this.santralModel = this.stringdata.santralCode;

        this.dataloggerModel = this.stringdata.dataloggerName;
        this.MPPTList = [];
        this.mttpIndex = 1;
        if ('MPPT' in this.stringdata) {
          this.MPPTList = this.stringdata.MPPT;
          this.mttpIndex = this.stringda.MPPT.length;
        }
      }
    },
    // datalogger listesi
    getDatalogger() {
      const params = {
        condiniton: {
          prefix: this.prefix,
          santral: this.santralData[this.santralModel].santral,
        },
      };
      getDataloggerList(params).then((res) => {
        const devList = [];
        const deviceList = {};

        if (helper.resIsEmpty(res)) {
          Object.keys(res.data.data).forEach((i) => {
            deviceList[res.data.data[i].deviceName] = res.data.data[i];
            devList.push({
              text: `${res.data.data[i].deviceName}`,
              value: `${res.data.data[i].deviceName}`,
            });
          });
        }

        this.dataloggerData = deviceList;
        this.dataloggerList = devList;
      });
    },

    /// / inverter tipleri listesi
    getInverterTypeList() {
      const params = {
        condiniton: {
          DeviceType: this.deviceTypeModel,
        },
      };

      getDeviceList(params).then((res) => {
        const devList = [];
        const deviceData = {};
        // this.deviceData = res.data.data;

        Object.keys(res.data.data).forEach((i) => {
          const dev = res.data.data[i].DeviceId;
          deviceData[dev] = res.data.data[i];
          devList.push({
            text: `${res.data.data[i].DeviceManufacture} ${res.data.data[i].DeviceModel}`,
            value: res.data.data[i].DeviceId,
          });
        });

        this.inverterTypeList = devList;
        this.deviceData = deviceData;
      });
    },

    getInverterType() {
      this.devheaders = [
        { text: 'text', value: 'text' },
        { text: 'key', value: 'key' },
        { text: 'unit', value: 'unit' },
        { text: 'type', value: 'type' },
        { text: 'formuletype', value: 'formuletype' },
      ];

      if (helper.is_Empty(this.dataloggerModel)) {
        return;
      }

      if (helper.is_Empty(this.deviceData)) {
        return;
      }

      const params = {
        condiniton: {
          STRINGBOXMODEL: this.deviceData[this.stringboxTypeModel].Model,
        },
      };

      if (this.dataloggerData[this.dataloggerModel] !== undefined) {
        const temp = this.dataloggerData[this.dataloggerModel];
        params.condiniton.Datalogger = temp.Model;
      }

      getInverterTypeList(params).then((res) => {
        // console.log(res);
        this.deviceParameterList = res.data.data[0].general_measure_list;
        this.deviceParameterModel = res.data.data[0].general_measure_list;

        this.itemPerPage = this.deviceParameterList.length;
      });
    },

    setStrinboxCreate() {
      const temp = this.dataloggerData[this.dataloggerModel];
      this.formdata.STRINGBOX = this.formdata.ID < 10 ? `COMBINER0${this.formdata.ID}` : `COMBINER${this.formdata.ID}`;
      const data = {
        STRINGBOXNAME: this.formdata.STRINGBOXNAME,
        ID: this.formdata.ID,

        STRINGBOX: this.formdata.STRINGBOX,
        stringboxStatus: this.stringboxStatus === true ? 'Active' : 'Passive',
        STRINGBOXMODEL: this.deviceData[this.stringboxTypeModel].Model,
        stringboxTypeDeviceId: this.deviceData[this.stringboxTypeModel].DeviceId,
        stringboxManufacture: this.deviceData[this.stringboxTypeModel].DeviceManufacture,
        stringboxDeviceModel: this.deviceData[this.stringboxTypeModel].DeviceModel,
        stringboxDeviceSerial: this.formdata.stringboxDeviceSerial,
        stringQuantity: this.formdata.stringQuantity,
        voltageQuantity: this.formdata.voltageQuantity,
        stringPanelQuantity: this.formdata.stringPanelQuantity,
        general_measure_list: this.deviceParameterModel,
        dataloggerName: temp.deviceName,
        dataloggerDeviceModel: temp.DeviceModel,
        dataloggerTypeDeviceId: temp.DeviceId,
        santral: temp.santral,
        santralCode: temp.santralCode,
        timeInterval: temp.timeInterval,
        MPPT: this.MPPTList,
      };

      // this.formdata

      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
          deviceType: this.deviceTypeModel,
          STRINGBOX: data.STRINGBOX,
          santral: data.santral,
          santralCode: data.santralCode,
        },
        data,
      };

      setStringbox(params).then(() => {
        // console.log(res);
        this.$emit('stringlist');
      });
    },
  },
};
</script>
